import React, { useEffect } from "react";

import { Form, Col, Row } from "react-bootstrap";
import { CheckCircle } from "react-bootstrap-icons";

import Store from "../store";

import ReactGA from "react-ga";
import canCookie from "../canCookie";
import { useTranslation } from "react-i18next";

const CheckoutSummary = () => {
  const [t] = useTranslation();
  const store = Store.useContainer();

  useEffect(() => {
    canCookie() &&
      ReactGA.pageview(window.location.pathname + window.location.search);
    window.scrollTo(0, 0);
  }, []);

  return (
    <Form className="checkout-form">
      <Row className="checkout-form-row">
        <Col xs={12} className="checkout-summary">
          <div>
            <CheckCircle size={"150"} color={"#39AEFD"} className="mb-5" />
          </div>
          <h2 style={{ color: "#39AEFD" }}>{t('finalize.thanks')}</h2>
          <p>
            {t('finalize.email')}
          </p>
          {store.formOptions.find((el) => el.checked) ? (
            <>
              <p>
                {t('finalize.trial')}
              </p>
              <p>
                {t('finalize.support')}
              </p>
            </>
          ) : (
            <>
              <p>
                {t('finalize.trial_14')}
              </p>
              <p>
                {t('finalize.support')}
              </p>
            </>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default CheckoutSummary;
