import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  IbanElement,
} from "@stripe/react-stripe-js";

import Store from "../store";
import { calculatePrice } from "../calculatePrice";
import { useTranslation } from "react-i18next";

const createOptions = () => {
  return {
    style: {
      base: {
        letterSpacing: "0.025em",
        fontFamily: "Arial MT",
        color: "#999999",
        fontSize: "18px",
        fontWeight: 400,
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#c23d4b",
      },
    },
  };
};

const CheckoutPayment = () => {
  const [t] = useTranslation();

  const store = Store.useContainer();
  const [formError, setFormError] = useState("");

  const prices = calculatePrice(
    store.form.offer,
    store.formOptions,
    store.form.period
  );

  const numberFormat = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const handleChange = (event) => {
    store.setForm((prev) => {
      return {
        ...prev,
        [event.elementType]: event,
      };
    });
  };

  return (
    <Form className="checkout-form">
      <h2 className="d-flex justify-content-center h5 font-weight-bold mb-5">
        {t('payment.method')}
      </h2>
      <Row className="checkout-form-row checkout-form-top d-flex justify-content-center align-center text-center">
        <Col xs={12} md={6}>
          <Form.Group>
            <Col sm={12}>
              <div style={{ display: "flex" }}>
                <Form.Check
                  label={t('payment.cb')}
                  type="radio"
                  id="CB"
                  name="CB"
                  onChange={(evt) => store.setPayment("CB")}
                  defaultChecked
                  style={{ display: "inline" }}
                />
                <Form.Check
                  label={t('payment.sepa')}
                  type="radio"
                  id="SEPA"
                  name="CB"
                  onChange={(evt) => store.setPayment("SEPA")}
                  style={{ display: "inline", marginLeft: "1em" }}
                />
              </div>
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className="divider"></div>
        </Col>
      </Row>
      <Row className="checkout-form-row">
        <Col xs={12}>
          <Form.Label>
            <strong>
              {t('payment.trial')}
            </strong>
          </Form.Label>
        </Col>
      </Row>
      {store.payment === "CB" && (
        <Row className="checkout-form-row">
          <Col xs={12} lg={6}>
            <Form.Group>
              <Form.Label>{t('payment.card_number')}</Form.Label>
              <CardNumberElement {...createOptions()} onChange={handleChange} />
            </Form.Group>
          </Col>
          <Col xs={12} lg={3}>
            <Form.Group>
              <Form.Label>{t('payment.expiration')}</Form.Label>
              <CardExpiryElement {...createOptions()} onChange={handleChange} />
            </Form.Group>
          </Col>
          <Col xs={12} lg={3}>
            <Form.Group>
              <Form.Label>{t('payment.cvc')}</Form.Label>
              <CardCvcElement {...createOptions()} onChange={handleChange} />
            </Form.Group>
          </Col>
        </Row>
      )}
      {store.payment === "SEPA" && (
        <React.Fragment>
          <Row className="checkout-form-row">
            <Col xs={12} lg={12}>
              <Form.Group>
                <Form.Label>{t('payment.iban')}</Form.Label>
                <IbanElement
                  options={{
                    supportedCountries: ["SEPA"],
                    placeholderCountry: "FR",
                  }}
                  {...createOptions()}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="checkout-form-row">
            <Col xs={12} lg={12} style={{ fontStyle: "italic" }}>
              {t('payment.sepa_legal_msg')}
            </Col>
          </Row>
        </React.Fragment>
      )}
      <Row>
        <Col xs={12}>
          <div className="divider"></div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={12}>
          <Form.Group>
            <Form.Label style={{ fontWeight: "bold" }}>{t('payment.referer')}</Form.Label>
            <Form.Control
              placeholder={t('payment.referer')}
              onChange={(evt) => {
                let { name, value } = evt.target;
                store.setForm((prev) => {
                  return {
                    ...prev,
                    promocode: value,
                  };
                });
              }}
              name="promocode"
              defaultValue={store.form.promocode}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div className="divider"></div>
        </Col>
      </Row>

      <Row>
        <Col xs={12} style={{ textAlign: "right" }}>
          <div style={{ fontWeight: "bold" }}>
            <span>{t('prices.recurrent_amount_ht')}</span>
            <span style={{ display: "inline-block", minWidth: "100px" }}>
              {numberFormat(prices.calculateTotalHt.toFixed(2))}&nbsp;&euro;
            </span>
          </div>
        </Col>
      </Row>
      {store.form.country === "FR" && (
        <Row>
          <Col xs={12} style={{ textAlign: "right" }}>
            <div style={{ fontWeight: "bold" }}>
              <span>{t('prices.vat')}</span>
              <span style={{ display: "inline-block", minWidth: "100px" }}>
                {numberFormat(
                  prices.calculateVAT(prices.calculateTotalHt.toFixed(2))
                )}
                &nbsp;&euro;
              </span>
            </div>
          </Col>
        </Row>
      )}
      {store.form.country === "FR" && (
        <Row style={{ marginBottom: "10px" }}>
          <Col xs={12} style={{ textAlign: "right" }}>
            <div style={{ fontWeight: "bold" }}>
              <span>{t('prices.recurrent_amount_ttc')}</span>
              <span style={{ display: "inline-block", minWidth: "100px" }}>
                {numberFormat(
                  prices.calculateTTC(prices.calculateTotalHt.toFixed(2))
                )}
                &nbsp;&euro;
              </span>
            </div>
          </Col>
        </Row>
      )}
      <Row className="checkout-form-row">
        <Col xs={12} lg={8} style={{ textAign: "left " }}>
          {formError && (
            <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
              {formError}
            </Form.Control.Feedback>
          )}
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div className="divider"></div>
        </Col>
      </Row>

      {store.formOptions[1].checked && (
        <>
          <Row>
            <Col xs={12} style={{ textAlign: "right" }}>
              <div>
                <span>
                  {t('prices.first_payment_ht')}
                </span>
                <span style={{ display: "inline-block", minWidth: "100px" }}>
                  {numberFormat(
                    (store.form.offer === "STANDARD_PLUS" ? 500 : 700).toFixed(
                      2
                    )
                  )}
                  &nbsp;&euro;
                </span>
              </div>
            </Col>
          </Row>
          {store.form.country === "FR" && (
            <Row>
              <Col xs={12} style={{ textAlign: "right" }}>
                <div>
                  <span>{t('prices.vat')}</span>
                  <span style={{ display: "inline-block", minWidth: "100px" }}>
                    {numberFormat(
                      (store.form.offer === "STANDARD_PLUS"
                        ? 500 * 0.2
                        : 700 * 0.2
                      ).toFixed(2)
                    )}
                    &nbsp;&euro;
                  </span>
                </div>
              </Col>
            </Row>
          )}
          {store.form.country === "FR" && (
            <Row style={{ marginBottom: "10px" }}>
              <Col xs={12} style={{ textAlign: "right" }}>
                <div>
                  <span>
                    {t('prices.first_payment')}
                  </span>
                  <span style={{ display: "inline-block", minWidth: "100px" }}>
                    {numberFormat(
                      (store.form.offer === "STANDARD_PLUS"
                        ? 500 * 1.2
                        : 700 * 1.2
                      ).toFixed(2)
                    )}
                    &nbsp;&euro;
                  </span>
                </div>
              </Col>
            </Row>
          )}
        </>
      )}
    </Form>
  );
};

export default CheckoutPayment;
