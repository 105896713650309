import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import fr from './fr.json'
import en from './en.json'
 
i18n
  .use(LanguageDetector)
  .init({
    resources: {
      fr: { translations: fr},
      en: { translations: en},
    },
    fallbackLng: 'en',
    debug: false,
 
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
 
    keySeparator: '.',

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },
 
    react: {
      wait: true
    },

    detection: {
      order: ['localStorage', 'navigator'],
    
      // keys or params to lookup language from
      lookupLocalStorage: 'audmns-lng',
    
      // cache user language on
      caches: ['localStorage'],
      excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
    
      // only detect languages that are in the whitelist
      checkWhitelist: true,
      checkForSimilarInWhitelist: false,
    }
  });
 
export default i18n;