import React from "react";
import { useMediaQuery } from '@react-hook/media-query';

import Store from "../store";
import { offerListPrice } from "../calculatePrice";

import { Card, Form } from "react-bootstrap";
import { XCircle, Check } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

function CheckoutOffer() {
  const [t] = useTranslation();

  const isMobile = useMediaQuery('only screen and (max-width: 767px)');
  const store = Store.useContainer();

  const numberFormat = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  return (
    <>
      <div className="btn-container">
        <button
          className={
            store.form.period === "MONTH"
              ? "btn-inscription-period-selected month"
              : "btn-inscription-period month"
          }
          onClick={(evt) =>
            store.setForm((prev) => {
              return {
                ...prev,
                period: "MONTH",
              };
            })
          }
        >
          {t("offer.monthly")}
        </button>
        <button
          className={
            store.form.period === "YEAR"
              ? "btn-inscription-period-selected annual"
              : "btn-inscription-period annual"
          }
          onClick={(evt) =>
            store.setForm((prev) => {
              return {
                ...prev,
                period: "YEAR",
              };
            })
          }
        >
          {t("offer.annual")}
        </button>
      </div>

      <div className="d-flex" style={{ textAlign: "center", flexDirection: isMobile ? 'column' : 'row' }}>
        <label style={{ cursor: "pointer" }} for="BASE1">
          <Card className="m-2 w-90" style={{ border: "#39AEFD solid 2px" }}>
            <Card.Header
              className="text-center h5"
              style={{
                backgroundColor: "#EBF5FB",
                borderRadius: "1em",
                minHeight: "82px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "600",
                textTransform: "uppercase",
                fontSize: "18px",
              }}
            >
              {t('offer.name.STANDARD_PLUS')}
            </Card.Header>

            <Card.Body>
              <Card.Text className="h6" style={{ color: "#39AEFD" }}>
                {t('offer.monthly_ht')}
              </Card.Text>
              <Card.Title
                style={{ color: "#39AEFD", fontSize: "3em" }}
                className="text-center"
              >
                {store.form.period === "YEAR"
                  ? numberFormat(offerListPrice.startMonthAnnualy) + " €"
                  : numberFormat(offerListPrice.startMonthMonthly) + " €"}
              </Card.Title>
              <Card.Text
                className="font-weight-bold"
                style={{ color: "#39AEFD", paddingBottom: "1em" }}
              >
                {t("offer.total")}{" "}
                {store.form.period === "YEAR"
                  ? numberFormat(offerListPrice.startYearAnnualy)
                  : numberFormat(offerListPrice.startYearMonthly)}
                € {t('offer.yearly_ht')}
              </Card.Text>
              <div
                className="card-lines"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Card.Text style={{ padding: "0.5em" }}>
                  <Check color={"green"} />
                  {t('offer.pack.std_features')}
                </Card.Text>
                <Card.Text style={{ padding: "0.5em" }}>
                  <Check color={"green"} />
                  {t('offer.pack.downloads', { amount: '50 000'})}
                </Card.Text>
                <Card.Text style={{ padding: "0.5em" }}>
                  <Check color={"green"} />
                  {t('offer.pack.podcasts', { amount: '10'})}
                </Card.Text>
                <Card.Text style={{ padding: "0.5em" }}>
                  <Check color={"green"} />
                  {t('offer.pack.storage')}
                </Card.Text>
                <Card.Text style={{ padding: "0.5em" }}>
                  <Check color={"green"} />
                  {t('offer.pack.users', { amount: '10'})}
                </Card.Text>
                <Card.Text style={{ padding: "0.5em" }}>
                  <XCircle color="red" />
                  &nbsp;{t('offer.pack.reporting')}
                </Card.Text>
                <Card.Text style={{ padding: "0.5em" }}>
                  <XCircle color="red" />
                  &nbsp;{t('offer.pack.ads')}
                </Card.Text>
              </div>
            </Card.Body>

            <div style={{
              paddingLeft: '1em',
              paddingRight: '1em',
            }}>
              <Form.Check
                style={{
                  paddingBottom: "1em",
                  fontWeight: "600",
                  fontSize: "1.2rem",
                }}
                label={t('offer.select')}
                type="radio"
                id="BASE1"
                name="SOCLE"
                checked={store.form.offer === "STANDARD_PLUS"}
                onClick={() => {
                  store.setErrorOffer(false);
                }}
                onChange={(evt) =>
                  store.setForm((prev) => {
                    return {
                      ...prev,
                      offer: "STANDARD_PLUS",
                      name: t('offer.name.STANDARD_PLUS'),
                    };
                  })
                }
              />
            </div>
          </Card>
        </label>
        <label style={{ cursor: "pointer" }} for="BASE2">
          <Card className="m-2 w-90" style={{ border: "#39AEFD solid 2px" }}>
            <Card.Header
              className="text-center h5"
              style={{
                backgroundColor: "#EBF5FB",
                borderRadius: "1em",
                minHeight: "82px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "600",
                textTransform: "uppercase",
                fontSize: "18px",
              }}
            >
              {t('offer.name.BUSINESS')}
            </Card.Header>

            <Card.Body>
              <Card.Text className="h6" style={{ color: "#39AEFD" }}>
                {t('offer.monthly_ht')}
              </Card.Text>
              <Card.Title style={{ color: "#39AEFD", fontSize: "3em" }}>
                {store.form.period === "YEAR"
                  ? numberFormat(offerListPrice.mediumMonthAnnualy) + " €"
                  : numberFormat(offerListPrice.mediumMonthMonthly) + " €"}
              </Card.Title>
              <Card.Text
                className="font-weight-bold"
                style={{ color: "#39AEFD", paddingBottom: "1em" }}
              >
                {t('offer.total')}{" "}
                {store.form.period === "YEAR"
                  ? numberFormat(offerListPrice.mediumYearAnnualy)
                  : numberFormat(offerListPrice.mediumYearMonthly)}
                € {t('offer.yearly_ht')}
              </Card.Text>
              <div
                className="card-lines"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Card.Text style={{ padding: "0.5em" }}>
                  <Check color={"green"} />
                  {t('offer.pack.std_features')}
                </Card.Text>
                <Card.Text style={{ padding: "0.5em" }}>
                  <Check color={"green"} />
                  {t('offer.pack.downloads', {amount: '150 000'})}
                </Card.Text>
                <Card.Text style={{ padding: "0.5em" }}>
                  <Check color={"green"} />
                  {t('offer.pack.podcasts', {amount: '30'})}
                </Card.Text>
                <Card.Text style={{ padding: "0.5em" }}>
                  <Check color={"green"} />
                  {t('offer.pack.storage')}
                </Card.Text>
                <Card.Text style={{ padding: "0.5em" }}>
                  <Check color={"green"} />
                  {t('offer.pack.users', {amount: '15'})}
                </Card.Text>
                <Card.Text style={{ padding: "0.5em" }}>
                  <Check color={"green"} />
                  {t('offer.pack.reporting')}
                </Card.Text>
                <Card.Text style={{ padding: "0.5em" }}>
                  <Check color={"green"} />
                  {t('offer.pack.ads')}
                </Card.Text>
              </div>
            </Card.Body>

            <div style={{
              paddingLeft: '1em',
              paddingRight: '1em',
            }}>
              <Form.Check
                style={{
                  paddingBottom: "1em",
                  fontWeight: "600",
                  fontSize: "1.2rem",
                }}
                label={t('offer.select')}
                type="radio"
                id="BASE2"
                name="SOCLE"
                onClick={() => store.setErrorOffer(false)}
                checked={store.form.offer === "BUSINESS"}
                onChange={(evt) =>
                  store.setForm((prev) => {
                    return {
                      ...prev,
                      offer: "BUSINESS",
                      name: t('offer.name.BUSINESS'),
                    };
                  })
                }
              />
            </div>
          </Card>
        </label>
        <label style={{ cursor: "pointer" }} for="BASE3">
          <Card className="m-2 w-90" style={{ border: "#39AEFD solid 2px" }}>
            <Card.Header
              className="text-center h5"
              style={{
                backgroundColor: "#EBF5FB",
                borderRadius: "1em",
                minHeight: "82px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "600",
                textTransform: "uppercase",
                fontSize: "18px",
              }}
            >
              {t('offer.name.PLATINUM')}
            </Card.Header>

            <Card.Body>
              <Card.Text className="h6" style={{ color: "#39AEFD" }}>
              {t('offer.monthly_ht')}
              </Card.Text>
              <Card.Title style={{ color: "#39AEFD", fontSize: "3em" }}>
                {store.form.period === "YEAR"
                  ? numberFormat(offerListPrice.premiumMonthAnnualy) + " €"
                  : numberFormat(offerListPrice.premiumMonthMonthly) + " €"}
              </Card.Title>
              <Card.Text
                className="font-weight-bold"
                style={{ color: "#39AEFD", paddingBottom: "1em" }}
              >
                {t('offer.total')}{" "}
                {store.form.period === "YEAR"
                  ? numberFormat(offerListPrice.premiumYearAnnualy)
                  : numberFormat(offerListPrice.premiumYearMonthly)}
                € {t('offer.yearly_ht')}
              </Card.Text>
              <div
                className="card-lines"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Card.Text style={{ padding: "0.5em" }}>
                  <Check color={"green"} />
                  {t('offer.pack.std_features')}
                </Card.Text>
                <Card.Text style={{ padding: "0.5em" }}>
                  <Check color={"green"} />
                  {t('offer.pack.downloads', {amount:'350 000'})}
                </Card.Text>
                <Card.Text style={{ padding: "0.5em" }}>
                  <Check color={"green"} />
                  {t('offer.pack.podcasts', {amount:'50'})}
                </Card.Text>
                <Card.Text style={{ padding: "0.5em" }}>
                  <Check color={"green"} />
                  {t('offer.pack.storage')}
                </Card.Text>
                <Card.Text style={{ padding: "0.5em" }}>
                  <Check color={"green"} />
                  {t('offer.pack.users', {amount:'30'})}
                </Card.Text>
                <Card.Text style={{ padding: "0.5em" }}>
                  <Check color={"green"} />
                  {t('offer.pack.reporting')}
                </Card.Text>
                <Card.Text style={{ padding: "0.5em" }}>
                  <Check color={"green"} />
                  {t('offer.pack.ads')}
                </Card.Text>
              </div>
            </Card.Body>

            <div style={{
              paddingLeft: '1em',
              paddingRight: '1em',
            }}>
              <Form.Check
                style={{
                  paddingBottom: "1em",
                  fontWeight: "600",
                  fontSize: "1.2rem",
                }}
                label={t('offer.select')}
                type="radio"
                id="BASE3"
                name="SOCLE"
                onClick={() => store.setErrorOffer(false)}
                checked={store.form.offer === "PLATINIUM"}
                onChange={(evt) =>
                  store.setForm((prev) => {
                    return {
                      ...prev,
                      offer: "PLATINIUM",
                      name: t('offer.name.PLATINUM'),
                    };
                  })
                }
              />
            </div>
          </Card>
        </label>
      </div>
      {store.errorOffer && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "2em",
          }}
        >
          <p
            style={{
              color: "red",
              fontSize: "1.2rem",
              fontWeight: "600",
            }}
          >
            {t('offer.no_offer')}
          </p>
        </div>
      )}
    </>
  );
}

export default CheckoutOffer;
