// Liste des offres

const offerListPrice = {
  startMonthAnnualy: 41,
  startYearAnnualy: 492,
  startMonthMonthly: 49,
  startYearMonthly: 588,

  mediumMonthAnnualy: 129,
  mediumYearAnnualy: 1548,
  mediumMonthMonthly: 149,
  mediumYearMonthly: 1788,

  premiumMonthAnnualy: 299,
  premiumYearAnnualy: 3588,
  premiumMonthMonthly: 349,
  premiumYearMonthly: 4188,
};

// Liste des options
const optionsListPrice = {
  1: {
    id: 1,
    name: "suivi",
    price: (n, o) => {
      return 400;
    },
  },

  2: {
    id: 2,
    name: "migration",
    oneShot: true,
    price: (n, o) => {
      if (o === "STANDARD_PLUS") {
        return 500;
      } else if (o === "BUSINESS" || "PLATINIUM") {
        return 700;
      } else {
        return null;
      }
    },
    description: (n, o) => {
      if (o === "STANDARD_PLUS") {
        return 'Setup "Les Grands Moyens" : accompagnement migration & import des stats';
      } else if (o === "BUSINESS") {
        return 'Setup "Les Grands " : accompagnement migration & import des stats';
      } else if (o === "PLATINIUM") {
        return 'Setup "Les" : accompagnement migration & import des stats';
      } else {
        return null;
      }
    },
  },

  3: {
    id: 3,
    name: "playb",
    price: (n, o) => {
      if (n <= 5) {
        return 99;
      } else if (n <= 10) {
        return 149;
      } else if (n <= 15) {
        return 179;
      } else if (n <= 20) {
        return 199;
      } else {
        return null;
      }
    },
  },
  4: {
    id: 4,
    percent: true,
    name: "paywall",
    price: (n, o) => {
      return 0;
    },
  },
  5: {
    id: 5,
    name: "acces",
    price: (n, o) => {
      if (n <= 50) {
        return 25;
      } else if (n <= 200) {
        return 50;
      } else if (n <= 500) {
        return 80;
      } else if (n <= 1000) {
        return 110;
      } else if (n <= 5000) {
        return 250;
      } else if (n <= 20000) {
        return 500;
      } else if (n <= 50000) {
        return 790;
      } else if (n <= 100000) {
        return 1120;
      } else if (n <= 200000) {
        return 1580;
      } else if (n <= 300000) {
        return 1930;
      } else if (n <= 500000) {
        return 2500;
      } else if (n <= 1000000) {
        return 3500;
      } else {
        return null;
      }
    },
  },
  6: {
    id: 6,
    name: "leadwall",
    price: (n, o) => {
      if (n <= 250) {
        return 125;
      } else if (n <= 750) {
        return 338;
      } else if (n <= 1500) {
        return 600;
      } else if (n <= 3000) {
        return 1050;
      } else if (n <= 7500) {
        return 2250;
      } else {
        return null;
      }
    },
  },
  7: {
    id: 7,
    name: "publication",
    price: (n, o) => {
      return 50;
    },
  },
  8: {
    id: 8,
    name: "ingestion",
    price: (n, o) => {
      if (n <= 200) {
        return 59;
      } else if (n <= 600) {
        return 149;
      } else {
        return null;
      }
    },
  },
  9: {
    id: 9,
    name: "data",
    price: (n, o) => {
      if (n <= 5) {
        return 39;
      } else if (n <= 50) {
        return 199;
      } else {
        return null;
      }
    },
  },
};

//// Selection de l'offre
const calculatePrice = (offer, options, period) => {
  const priceOffer = (() => {
    if (offer === "STANDARD_PLUS" && period === "MONTH") {
      return 49;
    } else if (offer === "STANDARD_PLUS" && period === "YEAR") {
      return 492;
    } else if (offer === "BUSINESS" && period === "MONTH") {
      return 149;
    } else if (offer === "BUSINESS" && period === "YEAR") {
      return 1548;
    } else if (offer === "PLATINIUM" && period === "MONTH") {
      return 349;
    } else if (offer === "PLATINIUM" && period === "YEAR") {
      return 3588;
    }
  })();

  /// Calcule de chaque options
  const checkedOptions = options
    .filter((el) => el.checked)
    .map((el) => ({
      ...el,
      price:
        el.oneShot !== true &&
        optionsListPrice[el.id].price(el.number, offer) *
          (period === "MONTH" ? 1 : 12),
    }));
  // Total des options HT
  const totalOptionsHt = checkedOptions.map((el) => el.price);

  // Total offre + options HT
  let calculateTotalHt;

  if (options.find((el) => el.checked)) {
    calculateTotalHt = priceOffer + totalOptionsHt.reduce((acc, x) => acc + x);
  } else {
    calculateTotalHt = priceOffer;
  }

  // Total de la TVA
  const calculateVAT = (p) => {
    let price = p * 0.2;
    return price.toFixed(2);
  };

  // Total TTC
  const calculateTTC = (p) => {
    let price = p * 1.2;
    return price.toFixed(2);
  };

  return {
    priceOffer,
    checkedOptions,
    calculateTotalHt,
    calculateVAT,
    calculateTTC,
  };
};

export {
  calculatePrice,
  optionsListPrice,
  offerListPrice,
};
