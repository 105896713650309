import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useMediaQuery } from '@react-hook/media-query';

import CheckoutCoord from "./CheckoutCoord";
import CheckoutOffer from "./CheckoutOffer";
import CheckoutOptions from "./CheckoutOptions";
import CheckoutPrice from "./CheckoutPrice";
import CheckoutPayment from "./CheckoutPayment";

import CheckoutSummary from "./CheckoutSummary";

import Store from "../store";

import Steps from "rc-steps";
import CheckoutBtnNav from "./CheckoutBtnNav";
import { useTranslation } from "react-i18next";

const { Step } = Steps;

const Checkout = () => {
  const [t] = useTranslation();
  const store = Store.useContainer();

  const isMobile = useMediaQuery('only screen and (max-width: 767px)');

  const getStatus = (i) => {
    if (i > store.maxStep) {
      return "wait";
    }

    if (i === store.maxStep) {
      return "process";
    }

    return "finish";
  };

  return (
    <>
      <div className="checkout-background">
        <Container className="container-checkout">
          <Row>
            <Col xs={12} className="nopad">
              <Steps
                style={{
                  border: "1px solid rgb(235, 237, 240)",
                  marginBottom: 24,
                  backgroundColor: "#fff",
                }}
                type="navigation"
                current={store.step}
                onChange={(i) => store.setStep(i)}
              >
                <Step
                  title={isMobile ? '' : t("navbar.offer")}
                  status={getStatus(0)}
                  disabled={0 > store.maxStep || store.paymentDone}
                />
                <Step
                  title={isMobile ? '' : t("navbar.options")}
                  status={getStatus(1)}
                  disabled={1 > store.maxStep || store.paymentDone}
                />
                <Step
                  title={isMobile ? '' : t("navbar.price")}
                  status={getStatus(2)}
                  disabled={2 > store.maxStep || store.paymentDone}
                />
                <Step
                  title={isMobile ? '' : t("navbar.information")}
                  disabled={3 > store.maxStep || store.paymentDone}
                  status={getStatus(3)}
                />
                <Step
                  title={isMobile ? '' : t("navbar.payment")}
                  status={getStatus(4)}
                  disabled={4 > store.maxStep || store.paymentDone}
                />
                <Step
                  title={isMobile ? '' : t("navbar.confirm")}
                  status={getStatus(5)}
                  disabled={5 > store.maxStep}
                />
              </Steps>
            </Col>
            <Col xs={12} className="checkout-shadow">
              {store.step === 3 && <CheckoutCoord />}
              {store.step === 0 && <CheckoutOffer />}
              {store.step === 1 && <CheckoutOptions />}
              {store.step === 2 && <CheckoutPrice />}
              {store.step === 4 && <CheckoutPayment />}
              {store.step === 5 && <CheckoutSummary />}
            </Col>
          </Row>
          <CheckoutBtnNav />
        </Container>
      </div>
    </>
  );
};

export default Checkout;
