import React, { useEffect, useState } from "react";
import Store from "../store";
import axios from "axios";

import { Col, Row, Spinner } from "react-bootstrap";
import { calculatePrice } from "../calculatePrice";
import html2canvas from "html2canvas";
import { API } from "../constants";
import trimCanvas from "trim-canvas";
import { useTranslation } from "react-i18next";

function CheckoutPrice() {
  const [t] = useTranslation();

  const store = Store.useContainer();
  const ref = React.createRef();

  const [devis, setDevis] = useState(null);

  const prices = calculatePrice(
    store.form.offer,
    store.formOptions,
    store.form.period
  );

  const numberFormat = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  useEffect(() => {
    store.setLoading(true);

    html2canvas(ref.current, {
      logging: false,
      useCORS: true,
    }).then(async (canvas) => {
      trimCanvas(canvas);

      const imgData = canvas.toDataURL();

      const result = await axios.post(`${API}/register/devis`, {
        email: store.form.email,
        imgData,
        w: canvas.width,
        h: canvas.height,
      });
      setDevis(result.data?.url);
      store.setDevis(result.data?.url);

      store.setLoading(false);
    });
  }, [store.form.period]);

  return (
    <>
      {store.loading && (
        <>
          <div className="loader-devis">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <h2 className="font-weight-bold h5">
                {t('prices.progress')}
              </h2>
            </div>
          </div>
        </>
      )}

      <div ref={ref}>
        <div className="btn-container">
          <button
            className={
              store.form.period === "MONTH"
                ? "btn-inscription-period-selected month"
                : "btn-inscription-period month"
            }
            onClick={(evt) =>
              store.setForm((prev) => {
                return {
                  ...prev,
                  period: "MONTH",
                };
              })
            }
          >
            {t('offer.monthly')}
          </button>
          <button
            className={
              store.form.period === "YEAR"
                ? "btn-inscription-period-selected annual"
                : "btn-inscription-period annual"
            }
            onClick={(evt) =>
              store.setForm((prev) => {
                return {
                  ...prev,
                  period: "YEAR",
                };
              })
            }
          >
            {t('offer.annual')}
          </button>
        </div>
        <h2 className="font-weight-bold h5">{t('prices.offer')}</h2>
        <div className="divider-options"></div>
        <Row>
          <Col sm={10}>
            <p>{t('prices.offer_name')} "{store.form.name}"</p>
          </Col>
          <Col sm={2}>
            <p className="text-right">
              {numberFormat(prices.priceOffer.toFixed(2))}
              &nbsp;&euro;
            </p>
          </Col>
        </Row>

        <div style={{ marginTop: "2rem" }}>
          <h2 className="font-weight-bold h5">{t('prices.options')}</h2>
          <div className="divider-options"></div>
        </div>
        {prices.checkedOptions.map(
          (el) =>
            el.oneShot !== true && (
              <div key={el.id}>
                <Row style={{ marginBottom: "1em" }}>
                  <Col sm={10}>
                    <Col>
                      <h3 className="h6 font-weight-bold">{t(`options.${el.nameOption}`)}</h3>
                      <p>&nbsp; &nbsp; {el.description(el.number, null, t)}</p>
                    </Col>
                  </Col>
                  <Col sm={2} style={{ textAlign: "right" }}>
                    <p>
                      {el.percent
                        ? ""
                        : numberFormat(el.price.toFixed(2)) + ` €`}
                    </p>
                  </Col>
                </Row>
              </div>
            )
        )}
        <Row>
          <Col xs={12} style={{ textAlign: "right" }}>
            <div style={{ fontWeight: "bold" }}>
              <span>{t('prices.recurrent_amount_ht')}</span>
              <span style={{ display: "inline-block", minWidth: "100px" }}>
                {numberFormat(prices.calculateTotalHt.toFixed(2))}&nbsp;&euro;
              </span>
            </div>
          </Col>
        </Row>
        {store.form.country === "FR" && (
          <Row>
            <Col xs={12} style={{ textAlign: "right" }}>
              <div style={{ fontWeight: "bold" }}>
                <span>{t('prices.vat')}</span>
                <span style={{ display: "inline-block", minWidth: "100px" }}>
                  {numberFormat(
                    prices.calculateVAT(prices.calculateTotalHt.toFixed(2))
                  )}
                  &nbsp;&euro;
                </span>
              </div>
            </Col>
          </Row>
        )}
        {store.form.country === "FR" && (
          <Row style={{ marginBottom: "10px" }}>
            <Col xs={12} style={{ textAlign: "right" }}>
              <div style={{ fontWeight: "bold" }}>
                <span>{t('prices.recurrent_amount_ttc')}</span>
                <span style={{ display: "inline-block", minWidth: "100px" }}>
                  {numberFormat(
                    prices.calculateTTC(prices.calculateTotalHt.toFixed(2))
                  )}
                  &nbsp;&euro;
                </span>
              </div>
            </Col>
          </Row>
        )}

        {store.formOptions[1].checked && (
          <>
            <div
              className="divider-options"
              style={{ marginTop: "2rem" }}
            ></div>
            <Row>
              <Col xs={12}>
                <Row>
                  <Col xs={12}>
                    <p
                      style={{
                        fontWeight: "600",
                        marginBottom: "1.5rem",
                        fontSize: "1rem",
                      }}
                    >
                      {t("prices.setup", {offer: store.form.name})}
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} style={{ textAlign: "right" }}>
                    <div>
                      <span>
                        {t('prices.first_payment_ht')}
                      </span>
                      <span
                        style={{ display: "inline-block", minWidth: "100px" }}
                      >
                        {numberFormat(
                          (store.form.offer === "STANDARD_PLUS"
                            ? 500
                            : 700
                          ).toFixed(2)
                        )}
                        &nbsp;&euro;
                      </span>
                    </div>
                  </Col>
                </Row>

                {store.form.country === "FR" && (
                  <Row>
                    <Col xs={12} style={{ textAlign: "right" }}>
                      <div>
                        <span>{t('prices.vat')}</span>
                        <span
                          style={{ display: "inline-block", minWidth: "100px" }}
                        >
                          {numberFormat(
                            (store.form.offer === "STANDARD_PLUS"
                              ? 500 * 0.2
                              : 700 * 0.2
                            ).toFixed(2)
                          )}
                          &nbsp;&euro;
                        </span>
                      </div>
                    </Col>
                  </Row>
                )}
                {store.form.country === "FR" && (
                  <Row style={{ marginBottom: "10px" }}>
                    <Col xs={12} style={{ textAlign: "right" }}>
                      <div>
                        <span>
                          {t('prices.first_payment')}
                        </span>
                        <span
                          style={{ display: "inline-block", minWidth: "100px" }}
                        >
                          {numberFormat(
                            (store.form.offer === "STANDARD_PLUS"
                              ? 500 * 1.2
                              : 700 * 1.2
                            ).toFixed(2)
                          )}
                          &nbsp;&euro;
                        </span>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </>
        )}
      </div>
      <div>
        {!store.loading && (
          <div
            className="container-pdf-price"
            style={{
              cursor: "pointer",
            }}
          >
            <a href={devis} target="_blank">
              <p
                style={{
                  color: "black",
                  textDecoration: "underline",
                  fontWeight: "700",
                  cursor: "pointer",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="#39AEFD"
                  class="bi bi-file-earmark-arrow-down"
                  viewBox="0 0 16 16"
                >
                  <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z" />
                  <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                </svg>
                {t('prices.download')}
              </p>
            </a>
          </div>
        )}
      </div>
    </>
  );
}

export default CheckoutPrice;
