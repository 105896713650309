import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";

import App from "./App";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUB_KEY } from "./constants";
import i18n from "./i18n/i18n";

import "./index.css";
import * as serviceWorker from "./serviceWorker";

const stripePromise = loadStripe(STRIPE_PUB_KEY);
ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
