import React, { useEffect } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Checkout from './components/Checkout';

import Store from './store'

import { GA } from './constants'
import ReactGA from 'react-ga';

function App() {
  useEffect(() => {
    ReactGA.initialize(GA);
  }, [])

  return (
    <Store.Provider>
      <Router>
        <Switch>
          <Route path="/custom"component={() => { 
            window.location.href = 'https://custom.audiomeans.fr/custom'; 
            return null;
          }}/>
          <Route path="/grand-compte"component={() => { 
            window.location.href = 'https://custom.audiomeans.fr/grand-compte'; 
            return null;
          }}/>
          <Route path="/*">
            <Checkout />
          </Route>
        </Switch>
      </Router>
    </Store.Provider>
  );
}

export default App;
