import { t } from "i18next";
import { useState } from "react";
import { createContainer } from "unstated-next";

const Store = () => {
  const [form, setForm] = useState({
    whoami: "individual",
    country: "FR",
    period: "YEAR",
    offer: "",
    name: "",
  });

  const [formOptions, setFormOptions] = useState([
    {
      id: 1,
      nameOption: "personalized_support",
      description: (n, o, t) => t("options.personalized_support_forfait"),
      checked: false,
    },
    {
      id: 2,
      nameOption: "migration",
      description: (n, o) => null,
      oneShot: true,
      checked: false,
    },
    {
      id: 3,
      nameOption: "playb",
      description: (n, o, t) => {
        let v = null;
        if (n <= 5) {
          v = 5;
        } else if (n <= 10) {
          v = 10;
        } else if (n <= 15) {
          v = 15;
        } else if (n <= 20) {
          v = 20;
        }

        if (v) {
          return t("options.playb_forfait", {
            amount: v
          });
        }
        return null;
      },
      checked: false,
      number: 0,
      numberMax: 20,
    },
    {
      id: 4,
      nameOption: "paywall",
      description: (n, o, t) => t("options.paywall_forfait"),
      checked: false,
      percent: true,
    },
    {
      id: 5,
      nameOption: "premium",
      description: (n, o, t) => {
        let v = null;
        if (n <= 50) {
          v = 50;
        } else if (n <= 200) {
          v = 200;
        } else if (n <= 500) {
          v = 500;
        } else if (n <= 1000) {
          v = '1 000';
        } else if (n <= 5000) {
          v = '5 000';
        } else if (n <= 20000) {
          v = '20 000';
        } else if (n <= 50000) {
          v = '50 000';
        } else if (n <= 100000) {
          v = '100 000';
        } else if (n <= 200000) {
          v = '200 000';
        } else if (n <= 300000) {
          v = '300 000';
        } else if (n <= 500000) {
          v = '500 000';
        } else if (n <= 1000000) {
          v = '1m';
        } else {
          return null;
        }

        if (v) {
          return t("options.premium_forfait", {
            amount: v
          });
        }
        return null;
      },
      checked: false,
      number: 0,
      numberMax: 1000000,
    },
    {
      id: 6,
      nameOption: "leadwall",
      description: (n, o, t) => {
        let v = null;
        if (n <= 250) {
          v = 250;
        } else if (n <= 750) {
          v = 750;
        } else if (n <= 1500) {
          v = '1 500';
        } else if (n <= 3000) {
          v = '3 000';
        } else if (n <= 7500) {
          v = '7 500';
        }

        if (v) {
          return t("options.leadwall_forfait", {
            amount: v
          });
        }
        return null;
      },
      checked: false,
      number: 0,
      numberMax: 7500,
    },
    {
      id: 7,
      nameOption: "api_pub",
      description: (n, o, t) => t('options.api_pub_forfait'),
      checked: false,
      number: 0,
      numberMax: 3000,
    },
    {
      id: 8,
      nameOption: "api_ingest",
      description: (n, o) => {
        let v = null;
        if (n <= 200) {
          v = 200;
        } else if (n <= 600) {
          v = 600;
        }

        if (v) {
          return t('options.api_ingest_forfait', {
            amount: v
          });
        }
        return null;
      },
      checked: false,
      number: 0,
      numberMax: 600,
    },
    {
      id: 9,
      nameOption: "api_data",
      description: (n, o) => {
        let v = null;
        if (n <= 5) {
          v = 5;
        } else if (n <= 50) {
          v = 50;
        }

        if (v) {
          return t('options.api_data_forfait', {
            amount: v
          });
        }
        return null;
      },
      checked: false,
      number: 0,
      numberMax: 50,
    },
  ]);

  const [errorOffer, setErrorOffer] = useState(false);
  const [errors, setErrors] = useState({});

  const [errorsOptions, setErrorsOptions] = useState({});
  const [errorMessage, setErrorMessage] = useState(false);

  const [step, setStep] = useState(0);
  const [maxStep, setMaxStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [devis, setDevis] = useState(null);

  const [cguAccepted, setCguAccepted] = useState(false);

  const [stripe, setStripe] = useState();
  const [payment, setPayment] = useState("CB");
  const [formPayment, setFormPayment] = useState({});
  const [formErrorPayment, setFormErrorPayment] = useState("");
  const [paymentDone, setPaymentDone] = useState(false);
  const [trialEnd, setTrialEnd] = useState();

  return {
    form,
    setForm,

    formOptions,
    setFormOptions,

    errorOffer,
    setErrorOffer,

    errors,
    setErrors,

    cguAccepted,
    setCguAccepted,

    errorMessage,
    setErrorMessage,

    errorsOptions,
    setErrorsOptions,

    stripe,
    setStripe,

    paymentDone,
    setPaymentDone,

    trialEnd,
    setTrialEnd,

    step,
    setStep,

    maxStep,
    setMaxStep,

    loading,
    setLoading,

    payment,
    setPayment,

    formPayment,
    setFormPayment,

    formErrorPayment,
    setFormErrorPayment,

    devis, setDevis,
  };
};

export default createContainer(Store);
