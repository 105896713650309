import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Store from "../store";

function CheckoutOptions() {
  const[t] = useTranslation();

  const store = Store.useContainer();

  const handleChange = (id, fields = () => ({})) => {
    store.setFormOptions(
      store.formOptions.map((item) =>
        item.id === id
          ? {
              ...item,
              checked: !store.formOptions.find((el) => el.id === id).checked,
              ...fields(item),
            }
          : { ...item }
      )
    );
  };

  return (
    <div>
      <div className="form-check m-3">
        <Row>
          <Col sm={9}>
            <input
              class="form-check-input"
              style={{
                width: "17px",
                height: "17px",
                accentColor: "#00aeff",
                color: "white",
              }}
              type="checkbox"
              id="suivi"
              checked={store.formOptions.find((el) => el.id === 1).checked}
              onChange={() => handleChange(1)}
              onClick={(evt) =>
                store.setFormOptions(
                  store.formOptions.map((item) =>
                    item.id === 1 ? { ...item, checked: true } : { ...item }
                  )
                )
              }
            />
            <label for="suivi">
              <p
                className="form-check-label font-weight-bold"
                style={{ color: "#00aeff", marginLeft: ".3em" }}
              >
                {t('options.personalized_support')}
              </p>
              <p>
                {t('options.personalized_support_description')}
              </p>
            </label>
          </Col>
        </Row>
      </div>

      <div class="form-check m-3">
        <Row>
          <Col sm={9}>
            <input
              className="form-check-input"
              style={{
                width: "17px",
                height: "17px",
                accentColor: "#00aeff",
                color: "white",
              }}
              type="checkbox"
              value=""
              id="accompagnement"
              checked={store.formOptions.find((el) => el.id === 2).checked}
              onChange={() => handleChange(2)}
              onClick={(evt) =>
                store.setFormOptions(
                  store.formOptions.map((item, key) =>
                    item.id === 2 ? { ...item, checked: true } : { ...item }
                  )
                )
              }
            />
            <label for="accompagnement">
              <p
                className="form-check-label font-weight-bold"
                style={{ color: "#00aeff", marginLeft: ".3em" }}
              >
                {t('options.migration')}
              </p>
              <p>
                {t('options.migration_description')}
              </p>
            </label>
          </Col>
        </Row>
      </div>

      <div class="form-check m-3">
        <Row>
          <Col sm={9}>
            <input
              class="form-check-input"
              style={{
                width: "17px",
                height: "17px",
                accentColor: "#00aeff",
                color: "white",
              }}
              type="checkbox"
              id="playb"
              checked={store.formOptions.find((el) => el.id === 3).checked}
              onChange={() => handleChange(3)}
            />
            <label for="playb">
              <p
                className="form-check-label font-weight-bold"
                style={{ color: "#00aeff", marginLeft: ".3em" }}
              >
                {t('options.playb')}
              </p>
              <p>
                {t('options.playb_description')}
              </p>
            </label>
          </Col>
          <Col sm={3}>
            <label>{t('options.playb_podcasts')}</label>
            <input
              type="number"
              min="1"
              max={store.formOptions.find((el) => el.id === 3).numberMax}
              value={parseInt(
                store.formOptions.find((el) => el.id === 3).number
              )}
              className={
                store.errorsOptions[3] ||
                (store.formOptions.find((el) => el.id === 3).checked &&
                  store.formOptions.find((el) => el.id === 3).number === 0)
                  ? "form-control-error"
                  : "form-control"
              }
              id="playb"
              name="playb"
              onChange={(e) => {
                store.setErrorMessage(false);
                store.setErrorsOptions((prev) => ({ ...prev, 3: false }));
                handleChange(3, (item) => ({
                  checked: true,
                  number:
                    e.target.value <= item.numberMax
                      ? parseInt(e.target.value)
                      : store.setErrorsOptions((prev) => ({
                          ...prev,
                          3: true,
                        })),
                }));
              }}
            />
            {store.errorsOptions[3] &&
              store.formOptions.map(
                (el) =>
                  el.id === 3 && (
                    <p className="form-message-error" key={el.id}>
                      {t('options.max_reached', { max: el.numberMax})}
                    </p>
                  )
              )}
          </Col>
        </Row>
      </div>

      <div class="form-check m-3">
        <Row>
          <Col sm={9}>
            <input
              class="form-check-input"
              style={{
                width: "17px",
                height: "17px",
                accentColor: "#00aeff",
                color: "white",
              }}
              type="checkbox"
              id="paywall"
              checked={store.formOptions.find((el) => el.id === 4).checked}
              onChange={() => handleChange(4)}
            />
            <label for="paywall">
              <p
                className="form-check-label font-weight-bold"
                style={{ color: "#00aeff", marginLeft: ".3em" }}
              >
                {t('options.paywall')}
              </p>
              <p>
                {t('options.paywall_description')}
              </p>
            </label>
          </Col>
        </Row>
      </div>

      <div class="form-check m-3">
        <Row>
          <Col sm={9}>
            <input
              class="form-check-input"
              style={{
                width: "17px",
                height: "17px",
                accentColor: "#00aeff",
                color: "white",
              }}
              type="checkbox"
              value=""
              id="reserve"
              checked={store.formOptions.find((el) => el.id === 5).checked}
              onChange={() => handleChange(5)}
            />
            <label for="reserve">
              <p
                className="form-check-label font-weight-bold"
                style={{ color: "#00aeff", marginLeft: ".3em" }}
              >
                {t('options.premium')}
              </p>
              <p>
                {t('options.premium_description')}
              </p>
            </label>
          </Col>
          <Col sm={3}>
            <label>{t('options.whitelist')}</label>
            <input
              type="number"
              min="1"
              max={store.formOptions.find((el) => el.id === 5).numberMax}
              value={parseInt(
                store.formOptions.find((el) => el.id === 5).number
              )}
              className={
                store.errorsOptions[5] ||
                (store.formOptions.find((el) => el.id === 5).checked &&
                  store.formOptions.find((el) => el.id === 5).number === 0)
                  ? "form-control-error"
                  : "form-control"
              }
              id="reserve"
              name="reserve"
              onChange={(e) => {
                store.setErrorMessage(false);
                store.setErrorsOptions((prev) => ({ ...prev, 5: false }));
                handleChange(5, (item) => ({
                  checked: true,
                  number:
                    e.target.value <= item.numberMax
                      ? parseInt(e.target.value)
                      : store.setErrorsOptions((prev) => ({
                          ...prev,
                          5: true,
                        })),
                }));
              }}
            />
            {store.errorsOptions[5] &&
              store.formOptions.map(
                (el) =>
                  el.id === 5 && (
                    <p className="form-message-error">
                      {t('options.max_reached', {max: el.numberMax})}
                    </p>
                  )
              )}
          </Col>
        </Row>
      </div>

      <div class="form-check m-3">
        <Row>
          <Col sm={9}>
            <input
              class="form-check-input"
              style={{
                width: "17px",
                height: "17px",
                accentColor: "#00aeff",
                color: "white",
              }}
              type="checkbox"
              id="leadwall"
              checked={store.formOptions.find((el) => el.id === 6).checked}
              onChange={() => handleChange(6)}
            />
            <label for="leadwall">
              <p
                className="form-check-label font-weight-bold"
                style={{ color: "#00aeff", marginLeft: ".3em" }}
              >
                {t('options.leadwall')}
              </p>
              <p>
                {t('options.leadwall_description')}
              </p>
            </label>
          </Col>
          <Col sm={3}>
            <label>{t('options.leadwall_objective')}</label>
            <input
              type="number"
              min="1"
              max={store.formOptions.find((el) => el.id === 6).numberMax}
              value={parseInt(
                store.formOptions.find((el) => el.id === 6).number
              )}
              className={
                store.errorsOptions[6] ||
                (store.formOptions.find((el) => el.id === 6).checked &&
                  store.formOptions.find((el) => el.id === 6).number === 0)
                  ? "form-control-error"
                  : "form-control"
              }
              id="leadwall"
              name="leadwall"
              onChange={(e) => {
                store.setErrorMessage(false);
                store.setErrorsOptions((prev) => ({ ...prev, 6: false }));
                handleChange(6, (item) => ({
                  checked: true,
                  number:
                    e.target.value <= item.numberMax
                      ? parseInt(e.target.value)
                      : store.setErrorsOptions((prev) => ({
                          ...prev,
                          6: true,
                        })),
                }));
              }}
            />
            {store.errorsOptions[6] &&
              store.formOptions.map(
                (el) =>
                  el.id === 6 && (
                    <p className="form-message-error" key={el.id}>
                      {t('options.max_reached', {max: el.numberMax})}
                    </p>
                  )
              )}
          </Col>
        </Row>
      </div>

      <div class="form-check m-3">
        <Row>
          <Col sm={9}>
            <input
              class="form-check-input"
              style={{
                width: "17px",
                height: "17px",
                accentColor: "#00aeff",
                color: "white",
              }}
              type="checkbox"
              value=""
              id="api-publication"
              checked={store.formOptions.find((el) => el.id === 7).checked}
              onChange={() => handleChange(7)}
            />
            <label for="api-publication">
              <p
                className="form-check-label font-weight-bold"
                style={{ color: "#00aeff", marginLeft: ".3em" }}
              >
                {t('options.api_pub')}
              </p>
              <p>
                {t('options.api_pub_description')}
              </p>
            </label>
          </Col>
          <Col sm={3}>
            <label>{t('options.api_pub_objective')}</label>
            <input
              type="number"
              min="1"
              max={store.formOptions.find((el) => el.id === 7).numberMax}
              value={parseInt(
                store.formOptions.find((el) => el.id === 7).number
              )}
              className={
                store.errorsOptions[7] ||
                (store.formOptions.find((el) => el.id === 7).checked &&
                  store.formOptions.find((el) => el.id === 7).number === 0)
                  ? "form-control-error"
                  : "form-control"
              }
              onChange={(e) => {
                store.setErrorMessage(false);
                store.setErrorsOptions((prev) => ({ ...prev, 7: false }));
                handleChange(7, (item) => ({
                  checked: true,
                  number:
                    e.target.value <= item.numberMax
                      ? parseInt(e.target.value)
                      : store.setErrorsOptions((prev) => ({
                          ...prev,
                          7: true,
                        })),
                }));
              }}
            />
            {store.errorsOptions[7] &&
              store.formOptions.map(
                (el) =>
                  el.id === 7 && (
                    <p className="form-message-error" key={el.id}>
                      {t('options.max_reached', {max: el.numberMax})}
                    </p>
                  )
              )}
          </Col>
        </Row>
      </div>

      <div class="form-check m-3">
        <Row>
          <Col sm={9}>
            <input
              class="form-check-input"
              style={{
                width: "17px",
                height: "17px",
                accentColor: "#00aeff",
                color: "white",
              }}
              type="checkbox"
              id="api-ingestion"
              checked={store.formOptions.find((el) => el.id === 8).checked}
              onChange={() => handleChange(8)}
            />
            <label for="api-ingestion">
              <p
                className="form-check-label font-weight-bold"
                style={{ color: "#00aeff", marginLeft: ".3em" }}
              >
                {t('options.api_ingest')}
              </p>
              <p>
                {t('options.api_ingest_description')}
              </p>
            </label>
          </Col>
          <Col sm={3}>
            <label>{t('options.api_ingest_objective')}</label>
            <input
              type="number"
              min="1"
              max={store.formOptions.find((el) => el.id === 8).numberMax}
              value={parseInt(
                store.formOptions.find((el) => el.id === 8).number
              )}
              className={
                store.errorsOptions[8] ||
                (store.formOptions.find((el) => el.id === 8).checked &&
                  store.formOptions.find((el) => el.id === 8).number === 0)
                  ? "form-control-error"
                  : "form-control"
              }
              onChange={(e) => {
                store.setErrorMessage(false);
                store.setErrorsOptions((prev) => ({ ...prev, 8: false }));
                handleChange(8, (item) => ({
                  checked: true,
                  number:
                    e.target.value <= item.numberMax
                      ? parseInt(e.target.value)
                      : store.setErrorsOptions((prev) => ({
                          ...prev,
                          8: true,
                        })),
                }));
              }}
            />
            {store.errorsOptions[8] &&
              store.formOptions.map(
                (el) =>
                  el.id === 8 && (
                    <p className="form-message-error" key={el.id}>
                      {t('options.max_reached', {max: el.numberMax})}
                    </p>
                  )
              )}
          </Col>
        </Row>
      </div>

      <div class="form-check m-3">
        <Row>
          <Col sm={9}>
            <input
              class="form-check-input"
              style={{
                width: "17px",
                height: "17px",
                accentColor: "#00aeff",
                color: "white",
              }}
              type="checkbox"
              id="api-data"
              checked={store.formOptions.find((el) => el.id === 9).checked}
              onChange={() => handleChange(9)}
            />
            <label for="api-data">
              <p
                className="form-check-label font-weight-bold"
                style={{ color: "#00aeff", marginLeft: ".3em" }}
              >
                {t('options.api_data')}
              </p>
              <p>
                {t('options.api_data_description')}
              </p>
            </label>
          </Col>
          <Col sm={3}>
            <label>{t('options.api_data_objective')}</label>
            <input
              type="number"
              min="1"
              max={parseInt(
                store.formOptions.find((el) => el.id === 9).numberMax
              )}
              value={parseInt(
                store.formOptions.find((el) => el.id === 9).number
              )}
              className={
                store.errorsOptions[9] ||
                (store.formOptions.find((el) => el.id === 9).checked &&
                  store.formOptions.find((el) => el.id === 9).number === 0)
                  ? "form-control-error"
                  : "form-control"
              }
              id="api-data"
              name="api-data"
              onChange={(e) => {
                store.setErrorMessage(false);
                store.setErrorsOptions((prev) => ({ ...prev, 9: false }));
                handleChange(9, (item) => ({
                  checked: true,
                  number:
                    e.target.value <= item.numberMax
                      ? parseInt(e.target.value)
                      : store.setErrorsOptions((prev) => ({
                          ...prev,
                          9: true,
                        })),
                }));
              }}
            />
            {store.errorsOptions[9] &&
              store.formOptions.map(
                (el) =>
                  el.id === 9 && (
                    <p className="form-message-error" key={el.id}>
                      {t('options.max_reached', {max: el.numberMax})}
                    </p>
                  )
              )}
          </Col>
        </Row>
      </div>
      {store.errorMessage && (
        <div>
          <p
            style={{
              color: "red",
              fontSize: "1.2rem",
              textAlign: "center",
              fontWeight: "600",
              padding: "2em",
            }}
          >
            {t('options.error')}
          </p>
        </div>
      )}
    </div>
  );
}

export default CheckoutOptions;
