import React, { useState, useEffect } from "react";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import Store from "../store";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import ReactGA from "react-ga";
import canCookie from "../canCookie";
import CountrySelect from "../utils/CountrySelect";
import { Trans, useTranslation } from "react-i18next";

const PASSWORD_REGEX = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);
const EMAIL_REGEX = new RegExp("[^@]+@[^\\.]+\\..+");

const CheckoutCoord = ({ gotoNextStep }) => {
  const [t] = useTranslation();

  const store = Store.useContainer();

  const [passwordType, setPasswordType] = useState("password");
  const [passwordConfirmType, setPasswordConfirmType] = useState("password");

  useEffect(() => {
    canCookie() &&
      ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (store.paymentDone) {
      gotoNextStep();
    }
  }, [gotoNextStep, store.paymentDone]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const checkErrors = (name, value) => {
    let keys = [];
    if (name) {
      keys.push(name);
    } else {
      keys = [
        "firstname",
        "lastname",
        "email",
        "password",
        "passwordConfirm",
        "address",
        "postalcode",
        "city",
        "country",
        "company",
        "siret",
        "vat",
      ];
    }

    let err = {},
      isError = false;
    for (let i = 0; i < keys.length; i++) {
      let k = keys[i],
        v = store.form[k] || "";
      if (name && keys[i] === name) {
        v = value;
      }

      if (k === "password") {
        err[k] = !PASSWORD_REGEX.test(v) || v === "";
      } else if (k === "passwordConfirm") {
        err[k] = v !== store.form.password || v === "";
      } else if (k === "email") {
        err[k] = !EMAIL_REGEX.test(v) || v === "";
      } else {
        if (
          store.form.whoami === "individual" &&
          ["company", "siret", "vat"].includes(k)
        ) {
          err[k] = false;
        } else if (
          store.form.whoami === "self" &&
          ["company", "vat"].includes(k)
        ) {
          err[k] = false;
        } else {
          err[k] = v === "";
        }
      }
    }

    let kk = Object.keys(err);
    for (let i = 0; i < kk.length; i++) {
      isError |= err[kk[i]];
    }

    err.form = isError
      ? t('form.error')
      : "";

    return {
      isError,
      err,
    };
  };

  const handleChange = (evt) => {
    let { name, value } = evt.target;

    let { err } = checkErrors(name, value);
    store.setErrors((prev) => {
      return {
        ...prev,
        ...err,
      };
    });

    store.setForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <Form className="checkout-form">
      <Row className="checkout-form-row">
        <Col xs={12} lg={12}>
          <Form.Group>
            <Form.Label>{t('form.field.email')} *</Form.Label>
            <Form.Control
              type="email"
              placeholder="jean@mail.com"
              isInvalid={store.errors.email}
              isValid={store.form.email && !store.errors.email}
              onChange={handleChange}
              name="email"
              defaultValue={store.form.email}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="checkout-form-row">
        <Col xs={12} lg={6}>
          <Form.Group>
            <Form.Label>{t('form.field.password')} *</Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="********"
                type={passwordType}
                isInvalid={store.errors.password}
                isValid={store.form.password && !store.errors.password}
                onChange={handleChange}
                name="password"
                defaultValue={store.form.password}
              />
              <InputGroup.Append>
                <InputGroup.Text
                  onClick={() =>
                    setPasswordType(
                      passwordType === "text" ? "password" : "text"
                    )
                  }
                >
                  {passwordType === "password" && <EyeSlash />}
                  {passwordType !== "password" && <Eye />}
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            <Form.Text className="text-muted">
              {t('form.field.password_rule')}
            </Form.Text>
          </Form.Group>
        </Col>

        <Col xs={12} lg={6}>
          <Form.Group>
            <Form.Label>{t('form.field.confirm_password')} *</Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="********"
                type={passwordConfirmType}
                isInvalid={store.errors.passwordConfirm}
                isValid={
                  store.form.passwordConfirm && !store.errors.passwordConfirm
                }
                onChange={handleChange}
                name="passwordConfirm"
                defaultValue={store.form.passwordConfirm}
              />
              <InputGroup.Append>
                <InputGroup.Text
                  onClick={() =>
                    setPasswordConfirmType(
                      passwordConfirmType === "text" ? "password" : "text"
                    )
                  }
                >
                  {passwordConfirmType === "password" && <EyeSlash />}
                  {passwordConfirmType !== "password" && <Eye />}
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className="divider"></div>
        </Col>
      </Row>
      <Row className="checkout-form-row">
        <Col xs={12} lg={6}>
          <Form.Group>
            <Form.Label>{t('form.field.i_am')}</Form.Label>
            <Form.Control
              as="select"
              onChange={handleChange}
              name="whoami"
              defaultValue={store.form.whoami || "individual"}
            >
              <option className="checkout-option" value="individual">
                {t('form.field.person')}
              </option>
              <option className="checkout-option" value="self">
                {t('form.field.self_employed')}
              </option>
              <option className="checkout-option" value="enterprise">
                {t('form.field.enterprise')}
              </option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row className="checkout-form-row">
        <Col xs={12} lg={6}>
          <Form.Group>
            <Form.Label>{t('form.field.firstname')} *</Form.Label>
            <Form.Control
              placeholder="Jean"
              isInvalid={store.errors.firstname}
              isValid={store.form.firstname && !store.errors.firstname}
              onChange={handleChange}
              name="firstname"
              defaultValue={store.form.firstname}
            />
          </Form.Group>
        </Col>
        <Col xs={12} lg={6}>
          <Form.Group>
            <Form.Label>{t('form.field.lastname')} *</Form.Label>
            <Form.Control
              placeholder="Martin"
              isInvalid={store.errors.lastname}
              isValid={store.form.lastname && !store.errors.lastname}
              onChange={handleChange}
              name="lastname"
              defaultValue={store.form.lastname}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className="divider"></div>
        </Col>
      </Row>
      <Row className="checkout-form-row">
        <Col xs={12}>
          <Form.Group>
            <Form.Label>{t('form.field.address')} *</Form.Label>
            <Form.Control
              placeholder={t('form.field.address')}
              isInvalid={store.errors.address}
              isValid={store.form.address && !store.errors.address}
              onChange={handleChange}
              name="address"
              defaultValue={store.form.address}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="checkout-form-row">
        <Col xs={12} lg={6}>
          <Form.Group>
            <Form.Label>{t('form.field.postal_code')} *</Form.Label>
            <Form.Control
              placeholder="75000"
              type="text"
              isInvalid={store.errors.postalcode}
              isValid={store.form.postalcode && !store.errors.postalcode}
              onChange={handleChange}
              name="postalcode"
              defaultValue={store.form.postalcode}
            />
          </Form.Group>
        </Col>
        <Col xs={12} lg={6}>
          <Form.Group>
            <Form.Label>{t('form.field.city')} *</Form.Label>
            <Form.Control
              placeholder="Paris"
              isInvalid={store.errors.city}
              isValid={store.form.city && !store.errors.city}
              onChange={handleChange}
              name="city"
              defaultValue={store.form.city}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="checkout-form-row">
        <CountrySelect handleChange={handleChange} store={store} />

        <Col xs={12} lg={6}>
          <Form.Group>
            <Form.Label>{t('form.field.phone')} *</Form.Label>
            <Form.Control
              placeholder="+33123456789"
              isInvalid={store.errors.phone}
              isValid={store.form.phone && !store.errors.phone}
              onChange={handleChange}
              name="phone"
              defaultValue={store.form.phone}
            />
          </Form.Group>
        </Col>
      </Row>
      {store.form.whoami === "enterprise" && (
        <>
          <Row>
            <Col xs={12}>
              <div className="divider"></div>
            </Col>
          </Row>
          <Row className="checkout-form-row">
            <Col xs={12} lg={12}>
              <Form.Group>
                <Form.Label>{t('form.field.enterprise_name')} *</Form.Label>
                <Form.Control
                  placeholder="Company SAS"
                  isInvalid={store.errors.company}
                  isValid={store.form.company && !store.errors.company}
                  onChange={handleChange}
                  name="company"
                  defaultValue={store.form.company}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="checkout-form-row">
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>{t('form.field.siret')} *</Form.Label>
                <Form.Control
                  placeholder="789 123 120 00001"
                  isInvalid={store.errors.siret}
                  isValid={store.form.siret && !store.errors.siret}
                  onChange={handleChange}
                  name="siret"
                  defaultValue={store.form.siret}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>{t('form.field.vat')} *</Form.Label>
                <Form.Control
                  placeholder="FR 40 123456824"
                  isInvalid={store.errors.vat}
                  isValid={store.form.vat && !store.errors.vat}
                  onChange={handleChange}
                  name="vat"
                  defaultValue={store.form.sivatret}
                />
              </Form.Group>
            </Col>
          </Row>
        </>
      )}
      {store.form.whoami === "self" && (
        <>
          <Row>
            <Col xs={12}>
              <div className="divider"></div>
            </Col>
          </Row>
          <Row className="checkout-form-row">
            <Col xs={12} lg={12}>
              <Form.Group>
                <Form.Label>{t('form.field.siret')} *</Form.Label>
                <Form.Control
                  placeholder="789 123 120 00001"
                  isInvalid={store.errors.siret}
                  isValid={store.form.siret && !store.errors.siret}
                  onChange={handleChange}
                  name="siret"
                  defaultValue={store.form.siret}
                />
              </Form.Group>
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col xs={12}>
          <div className="divider"></div>
        </Col>
      </Row>
      <Row className="checkout-form-row">
        <Col xs={12} lg={12}>
          <Form.Group>
            <Form.Check
              type="checkbox"
              id="cgu"
              name="checkCgu"
              onChange={(evt) => {
                store.setCguAccepted(evt.target.checked);
              }}
              checked={store.cguAccepted}
              label={
                <label htmlFor="cgu">
                  <span>
                    <Trans i18nKey="form.field.cgu">
                      J'ai lu et j'accepte les{" "}
                      <a href="https://audiomeans.fr/cgu-cgv" target="_blank">
                        conditions générales d'utilisation
                      </a>
                    </Trans>
                  </span>
                </label>
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="checkout-form-row">
        <Col xs={12} lg={6} style={{ textAign: "left " }}>
          {store.errors.form && (
            <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
              {store.errors.form}
            </Form.Control.Feedback>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default CheckoutCoord;
