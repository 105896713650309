import React, { useEffect } from "react";
import Store from "../store";

import { API } from "../constants";
import axios from "axios";

import ReactGA from "react-ga";

import { Button, Spinner } from "react-bootstrap";
import { Lock } from "react-bootstrap-icons";

import {
  CardNumberElement,
  IbanElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";

const PASSWORD_REGEX = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);
const EMAIL_REGEX = new RegExp("[^@]+@[^\\.]+\\..+");

const FIELDS = {
  CB: ["cardNumber", "cardExpiry", "cardCvc"],
  SEPA: ["iban"],
};

function CheckoutBtnNav() {
  const [t] = useTranslation();

  const store = Store.useContainer();
  const elements = useElements();
  const stripe = useStripe();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const checkErrors = (name, value) => {
    let keys = [];
    if (name) {
      keys.push(name);
    } else {
      keys = [
        "firstname",
        "lastname",
        "email",
        "password",
        "passwordConfirm",
        "address",
        "postalcode",
        "city",
        "country",
        "company",
        "siret",
        "vat",
      ];
    }

    let err = {},
      isError = false;
    for (let i = 0; i < keys.length; i++) {
      let k = keys[i],
        v = store.form[k] || "";
      if (name && keys[i] === name) {
        v = value;
      }

      if (k === "password") {
        err[k] = !PASSWORD_REGEX.test(v) || v === "";
      } else if (k === "passwordConfirm") {
        err[k] = v !== store.form.password || v === "";
      } else if (k === "email") {
        err[k] = !EMAIL_REGEX.test(v) || v === "";
      } else {
        if (
          store.form.whoami === "individual" &&
          ["company", "siret", "vat"].includes(k)
        ) {
          err[k] = false;
        } else if (
          store.form.whoami === "self" &&
          ["company", "vat"].includes(k)
        ) {
          err[k] = false;
        } else {
          err[k] = v === "";
        }
      }
    }

    let kk = Object.keys(err);
    for (let i = 0; i < kk.length; i++) {
      isError |= err[kk[i]];
    }

    err.form = isError
      ? t('form.error')
      : "";

    return {
      isError,
      err,
    };
  };

  const handleSubmitCoords = async (evt) => {
    store.setLoading(true);
    let { isError, err } = checkErrors();

    store.setErrors((prev) => {
      return {
        ...prev,
        ...err,
      };
    });

    if (isError) {
      store.setLoading(false);
      return;
    }

    if (!store.cguAccepted) {
      store.setErrors((prev) => {
        return {
          ...prev,
          form: t('form.cgu'),
        };
      });

      store.setLoading(false);
      return;
    }

    const result = await axios.post(`${API}/register/user`, {
      ...store.form,
    });

    const { status, error } = result.data;
    if (status === "ERROR") {
      if (error === "USER_EXISTS") {
        store.setErrors((prev) => {
          return {
            ...prev,
            form: t('form.user'),
          };
        });
      } else {
        store.setErrors((prev) => {
          return {
            ...prev,
            form: t('form.generic'),
          };
        });
      }

      store.setLoading(false);
      return;
    }

    // Send billing options
    const options = store.formOptions
        .filter((el) => el.checked)
        .map((el) => ({ id: el.id, number: el.number }));
    await axios.post(`${API}/register/billingoptions`, {
      offer: store.form.offer,
      options,
      email: store.form.email,
      devis: store.devis,
    });

    store.setStep(store.step + 1);
    store.setLoading(false);
  };

  const handleSubmitPayment = async () => {
    try {
      store.setLoading(true);

      let fields = FIELDS[store.payment];
      let isError = false;
      for (let i = 0; i < fields.length; i++) {
        let f = fields[i];
        if (
          typeof store.form[f] === "undefined" ||
          store.form[f].empty ||
          store.form[f].error ||
          !store.form[f].complete
        ) {
          isError = true;
          break;
        }
      }

      if (isError) {
        store.setFormErrorPayment(
          t('form.error')
        );
        store.setLoading(false);
        return;
      }

      store.setFormErrorPayment("");

      let paymentSrc = {};
      if (store.payment === "CB") {
        paymentSrc.type = "card";
        paymentSrc.card = elements.getElement(CardNumberElement);
      } else {
        paymentSrc.type = "sepa_debit";
        paymentSrc.sepa_debit = elements.getElement(IbanElement);
      }

      let { data: setupData } = await axios.post(`${API}/register/setup`, {
        ...store.form,
      });

      if (setupData.status === "ERROR") {
        store.setLoading(false);
        store.setFormErrorPayment(
          t('form.error_no_payment')
        );
        return;
      }

      let result;
      try {
        if (paymentSrc.type === "card") {
          result = await stripe.confirmCardSetup(setupData.secret, {
            payment_method: {
              ...paymentSrc,
              billing_details: {
                email: store.form.email,
                name: `${store.form.firstname} ${store.form.lastname}`,
                address: {
                  postal_code: `${store.form.postalcode}`,
                  country: store.form.country,
                  city: store.form.city,
                  line1: store.form.address,
                },
              },
            },
          });
        } else {
          result = await stripe.confirmSepaDebitSetup(setupData.secret, {
            payment_method: {
              ...paymentSrc,
              billing_details: {
                email: store.form.email,
                name: `${store.form.firstname} ${store.form.lastname}`,
                address: {
                  postal_code: `${store.form.postalcode}`,
                  country: store.form.country,
                  city: store.form.city,
                  line1: store.form.address,
                },
              },
            },
          });
        }
      } catch (e) {
        console.log(e);
      }

      if (typeof result.error !== "undefined") {
        if (result.error.code === "setup_intent_authentication_failure") {
          store.setFormErrorPayment(
            t('form.stripe.authent')
          );
        } else if (result.error.code === "card_declined") {
          store.setFormErrorPayment(
            t('form.stripe.declined')
          );
        } else if (result.error.code === "expired_card") {
          store.setFormErrorPayment(t('form.stripe.expired'));
        } else if (result.error.code === "incorrect_cvc") {
          store.setFormErrorPayment(
            t('form.stripe.cvc')
          );
        } else if (result.error.code === "incorrect_number") {
          store.setFormErrorPayment(
            t('form.stripe.number')
          );
        } else {
          store.setFormErrorPayment(
            t('form.stripe.unknown')
          );
        }
        store.setLoading(false);
        return;
      }

      let { data } = await axios.post(`${API}/register/intent`, {
        ...store.form,
        paymentMethod: result.setupIntent.payment_method,
        setupIntent: result.setupIntent.id,
      });

      if (data.status === "ERROR") {
        if (data.error === "USER_NOT_FOUND") {
          store.setFormErrorPayment(
            t('form.error_no_payment')
          );
        } else if (data.error === "card_declined") {
          store.setFormErrorPayment(
            t('form.stripe.declined')
          );
        } else if (data.error === "expired_card") {
          store.setFormErrorPayment(t('form.stripe.expired'));
        } else if (data.error === "incorrect_cvc") {
          store.setFormErrorPayment(
            t('form.stripe.cvc')
          );
        } else if (data.error === "incorrect_number") {
          store.setFormErrorPayment(
            t('form.stripe.number')
          );
        } else {
          store.setFormErrorPayment(
            t('form.stripe.unknown')
          );
        }
        store.setLoading(false);
        return;
      }

      store.setLoading(false);
      store.setTrialEnd(data.trial_end);
      store.setPaymentDone(true);
      store.setStep(store.step + 1);
      store.setMaxStep(store.step);
    } catch (e) {
      console.log(e);
      store.setLoading(false);
    }
  };

  const handleNextStep = async () => {
    if (store.step === 3) {
      await handleSubmitCoords();
    }

    if (store.step === 0) {
      if (store.form.offer) {
        store.setStep(store.step + 1);
        store.setMaxStep(store.step);

        try {
          document.body.scrollTo(0, 0);
        } catch {}
        window.scrollTo(-100, 0);

      } else {
        store.setErrorOffer(true);
      }
    }

    if (store.step === 1) {
      const optionsNumberNull = store.formOptions.filter(
        (el) =>
          typeof el.number !== "undefined" &&
          ((parseInt(el.number) === 0 && el.checked) ||
            (isNaN(el.number) && el.checked))
      );

      if (
        !Object.values(store.errorsOptions).find((el) => el) &&
        !store.errorReserve &&
        !store.errorLeadwall &&
        !store.errorPublication &&
        !store.errorIngestion &&
        !store.errorData &&
        optionsNumberNull.length === 0
      ) {
        store.setStep(store.step + 1);
        store.setMaxStep(store.step);
        store.setErrorMessage(false);

        try {
          document.body.scrollTo(0, 0);
        } catch {}
        window.scrollTo(-100, 0);

        store.setLoading(false);
        return;
      } else {
        store.setErrorMessage(true);
        store.setErrorsOptions({});
        store.setLoading(false);
      }
    }

    if (store.step === 2) {
      store.setStep(store.step + 1);
      store.setMaxStep(store.step);
    }

    if (store.step === 4) {
      await handleSubmitPayment();
    }
  };

  const handlePreviousStep = () => {
    store.setStep(store.step - 1);
    store.setMaxStep(store.step);
  };

  return (
    <div className="d-flex">
      <div className="p-2">
        {store.step !== 0 && store.step !== 5 && (
          <button
            className="btn-nav-inscription"
            onClick={handlePreviousStep}
            disabled={store.loading}
          >
            {store.loading && (
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {!store.loading && t('form.button.BACK')}
          </button>
        )}
      </div>

      <div className="ml-auto p-2">
        {store.step !== 4 && store.step !== 5 && (
          <button
            className="btn-nav-inscription"
            onClick={handleNextStep}
            disabled={store.loading}
          >
            {store.loading && (
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {!store.loading && t('form.button.NEXT')}
          </button>
        )}
      </div>

      {store.step === 4 && (
        <div className="ml-auto p-2">
          <Button
            className="btn-nav-inscription"
            type="submit"
            onClick={(evt) => {
              handleSubmitPayment(evt);
            }}
            disabled={store.loading}
          >
            {store.loading && (
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}

            {!store.loading && (
              <>
                <Lock color="#fff" size={24} style={{ marginRight: "15px" }} />
                {t('form.button.PAY')}
              </>
            )}
          </Button>
        </div>
      )}
    </div>
  );
}

export default CheckoutBtnNav;
